const CAPABILITIES = {
    pocketPosApp: false,
    salesApp: false,
    deliveryApp: false,
    CRV: true,
    customerEnhancement: true,
    mobileUnit: false,
    continuousStockType: true,
    dynamicProductBundle: true,
    salesSystem: true,
    onlineStore: false,
    orderManagementSystem: false,
    terminalPos: true,
    thirdPartyPos: false,
    activities: false,
    realEstate: false,
    services: true,
    realEstateFeaturedProperties: false,
    realEstateFeaturedOffPlans: false,
    lead: false,
    lms: false,
    translationSupport: false,
    hrm: false,
    cinema: false,
    shipping: false,
    onlineLearning: false,
    extSales: false,
    arabicReceipt: true,
    restaurant: false,
    fnbEvent: false,
    templateEngine: true,
    production: true,
    extendedPosCheckout: false,
    templateEngineInvoice: true,
    templateEngineQuotation: false,
    cargo: false,
    uomInPrint: true,
};

const UNSAFE_CAPABILITIES = {
    salesReturnWithoutOriginalSales: true,
};

/**
 *
 * @param {(
 * "CRV" |
 * "customerEnhancement" |
 * "mobileUnit"
 * )} name
 */
export const hasCapabilitySupport = (name) => CAPABILITIES[name];

/**
 *
 * @param {(
 * "salesReturnWithoutOriginalSales"
 * )} name
 */
export const has_UNSAFE_CapabilitySupport = (name) => UNSAFE_CAPABILITIES[name];
